import { render, staticRenderFns } from "./EditNoteModal.vue?vue&type=template&id=018e5032&scoped=true"
import script from "./EditNoteModal.vue?vue&type=script&lang=js"
export * from "./EditNoteModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018e5032",
  null
  
)

export default component.exports