<template>
  <form :action="formPath" method="post">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Close Review</p>
        <button class="delete" type="button" aria-label="close" v-on:click="cancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <div class="control">
            <input name="authenticity_token" type="hidden" :value="csrfToken">

            <b-field label="Deadline">
              <b-input
                ref="deadlineAt"
                name="deadline_at"
                type="date"
                :value="deadlineAt"
                required
              ></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button type="submit" class="button is-primary">Close Review</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "PromptCloseModal",
  props: {
    csrfToken: String,
    reviewId: Number,
    deadlineAt: String
  },
  mounted () {
    this.$refs.deadlineAt.focus()
  },
  data: function() {
    return {};
  },
  computed: {
    formPath: function() {
      return ['/reviews', this.reviewId, 'close'].join('/')
    }
  },
  methods: {
    cancel: function() {
      this.$emit('close');
    }
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
