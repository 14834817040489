<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>  

    <div class="box">
      <form @submit.prevent="processForm()" v-if="!isLoading">
        <div class="columns">
          <div class="column is-half">
            <b-field label="Report Template">
              <b-select v-model="report.report_template_id" expanded>
                <option v-for="reportTemplate in reportTemplates" :key="reportTemplate.id" :value="reportTemplate.id">{{reportTemplate.title}}</option>
              </b-select>
            </b-field>

            <b-field label="Description" v-if="reportTemplate !== null">
              {{reportTemplate.description}}
            </b-field>
          </div>

          <div class="column is-half">
            <b-field v-if="reportTemplate !== null">
              <div class="columns">
                <div class="column" v-for="param in Object.keys(report.params)" :key="param">
                  <b-field :label="reportTemplate.params[param].label">
                    <b-select v-if="reportTemplate.params[param].type === 'year'" v-model="report.params[param]" expanded>
                      <option v-if="!reportTemplate.params[param].required" value=null>All</option>
                      <option v-for="year in years" :value="year">{{year}}</option>
                    </b-select>

                    <b-select v-if="reportTemplate.params[param].type === 'group'" v-model="report.params[param]" expanded>
                      <option v-if="!reportTemplate.params[param].required" value=null>All</option>
                      <option v-for="group in groups" :value="group.id">{{group.name}}</option>
                    </b-select>   

                    <b-select v-if="reportTemplate.params[param].type === 'author'" v-model="report.params[param]" expanded>
                      <option v-if="!reportTemplate.params[param].required" value=null>All</option>
                      <option v-for="author in authors" :value="author.id">{{author.full_name}}</option>
                    </b-select>    

                    <b-select v-if="reportTemplate.params[param].type === 'reviewer'" v-model="report.params[param]" expanded>
                      <option v-if="!reportTemplate.params[param].required" value=null>All</option>
                      <option v-for="reviewer in reviewers" :value="reviewer.id">{{reviewer.full_name}}</option>
                    </b-select>
                  </b-field> 
                </div>
              </div>          
            </b-field>
          </div>
        </div>

        <hr>
        <b-button type="is-success" native-type="submit">Generate</b-button>
      </form>
    </div>

    <hr>
    
    <p v-if="reports.length === 0">No Reports</p>
    <table v-else class="table is-fullwidth">
      <thead>
        <tr>
          <th>Title</th>
          <th>Parameters</th>
          <th>Generated At</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, index) in reports" :key="index" :value="report">
          <td><a :href="'/reports/' + report.id">{{report.title}}</a></td>
          <td>
            <ul>
              <li v-for="friendly_param in report.friendly_params">{{friendly_param}}</li>
            </ul>
          </td>
          <td>{{formatDateTime(report.created_at)}}</td>
          <td><b-button size="is-small" type="is-danger" icon-left="trash" @click="promptDelete(report.id, index)">Delete</b-button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import formatMixin from '@/mixins/formatMixin.js'
import formFieldsMixin from '@/mixins/formFieldsMixin.js'

export default {
  name: "ListReports",
  mixins: [formatMixin, formFieldsMixin],  
  mounted () {
    Promise.all([
                  this.getReportTemplates(), 
                  this.getReports(),
                  this.getAuthors(),
                  this.getReviewers(),
                  this.getGroups()
                ]).then(() => {
      this.isLoading = false
    })
  },
  data() {
    return {
      isLoading: true,
      reports: [],
      reportTemplates: [],
      groups: [],
      authors: [],
      reviewers: [],
      report: { report_template_id: null, params: {} },
      errors: {}
    }
  },
  computed: {
    reportTemplate () {
      if(this.reportTemplates.length == 0) {
        return null
      }

      return this.reportTemplates.find((reportTemplate) => reportTemplate.id == this.report.report_template_id)
    },
    years () {
      const startYear = 2020
      const currentYear = new Date().getFullYear()
      const yearsArray = []

      for (let year = startYear; year <= currentYear; year++) {
          yearsArray.push(year)
      }

      return yearsArray
    }
  },
  watch: {
    'reportTemplate': function () {
      this.report.params = {}

      Object.keys(this.reportTemplate.params).forEach((key) => this.report.params[key] = this.reportTemplate.params[key].default)
    }    
  },  
  methods: {
    getReportTemplates () {
      var url = `/report_templates`

      this.$secured.get(url)
        .then(response => {
          this.reportTemplates = response.data
          this.report.report_template_id = this.reportTemplates[0].id
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get report templates: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          }) 
        }) 
    },
    getReports () {
      var url = `/reports`

      this.$secured.get(url)
        .then(response => {
          this.reports = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get reports: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          }) 
        }) 
    },
    getAuthors () {
      var url = `/users?author=true`

      this.$secured.get(url)
        .then(response => {
          this.authors = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get authors: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    }, 
    getReviewers () {
      var url = `/users?reviewer=true`

      this.$secured.get(url)
        .then(response => {
          this.reviewers = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get reviewers: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },        
    getGroups () {
      var url = `/groups`

      this.$secured.get(url)
        .then(response => {
          this.groups = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get groups: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },            
    processForm () {
      var params = {
        report: this.report
      }

      this.addReport(params)
    },
    addReport (params) {
      this.$secured.post('/reports', params)
      .then((response) => {
        var report = response.data

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully added report`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = `/reports/${report.id}`
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to add report: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    },
    promptDelete (reportId, index) {
      this.$buefy.dialog.confirm({
        title: 'Delete Report',
        message: 'Are you sure you want to <b>delete</b> this report?',
        confirmText: 'Delete Report',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteReport(reportId, index)
      })
    },
    deleteReport (reportId, index) {
      this.$secured.delete(`/reports/${reportId}`)
      .then(response => {
        response
        this.reports.splice(index, 1)

        this.$buefy.toast.open({
          duration: 1000,
          message: `Deleted`,
          position: 'is-bottom',
          type: 'is-success'
        })
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to delete item.`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
    },        
  }  
}
</script>
