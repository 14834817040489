export default {
  methods: {
    fieldErrors (field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType (field) {
      if(this.errors[field] !== undefined) {
        return 'is-danger'
      }

      return ''
    },
    getNestedErrors (errors, attribute, index) {
      var combinedAttribute = `${attribute}[${index}]`

      if(Object.prototype.hasOwnProperty.call(errors, combinedAttribute)) {
        return errors[combinedAttribute]
      }

      return {}
    },
    formOperation () {
      return this.isNew() ? 'New' : 'Edit'
    },
    isNew() {
      return !this.isEdit()
    },
    isEdit() {
      return this.id !== undefined
    },
    isObject (obj) {
        return (typeof obj === "object") && obj !== null
    },
    isArray (arr) {
      return Array.isArray(arr)
    },
    renameKeys (keysMap, obj) {
      if (this.isArray(obj)) {
        return obj.map(element => this.renameKeys(keysMap, element))
      } else if (this.isObject(obj)) {
        return this.renameKeysInObj(keysMap, obj)
      } else {
        return obj
      }
    },
    renameKeysInObj (keysMap, obj) {
      return Object
      .keys(obj)
      .filter(([value, key]) => value && (keysMap[key] === undefined || keysMap[key] !== null))
      .reduce((result, key) => ({
        ...result,
        ...keysMap[key] === null ? {} : { [keysMap[key] || key]: this.isObject(obj[key]) ? this.renameKeys(keysMap, obj[key]) : obj[key] }
      }), {})       
    }        
  }
}
