<template>
  <div>
    <form v-if="!isLoading" id="groupForm" @submit.prevent="processForm()">
      <div class="columns">
        <div class="column is-half">
          <b-field label="Title" :type="fieldType('title')" :message="fieldErrors('title')">
            <b-input v-model="paper.title" :autofocus="true"></b-input>
          </b-field>

          <div class="columns">
            <div class="column is-half">
              <b-field label="Authoring Group" :type="fieldType('authoring_group_id')" :message="fieldErrors('authoring_group_id')">
                <b-select v-model="paper.authoring_group_id" expanded>
                  <option v-for="authoringGroup in filteredGroups" :key="authoringGroup.id" :value="authoringGroup.id">{{authoringGroup.name}}</option>
                </b-select>
              </b-field>

              <b-field label="Joint Group" :type="fieldType('joint_group_id')" :message="fieldErrors('joint_group_id')">
                <b-select v-model="paper.joint_group_id" expanded>
                  <option value=null>None</option>
                  <option v-for="jointGroup in filteredGroups" :key="jointGroup.id" :value="jointGroup.id">{{jointGroup.name}}</option>
                </b-select>
              </b-field>                               
            </div>

            <div class="column is-half">
              <b-field label="Corresponding Author" :type="fieldType('corresponding_author_id')" :message="fieldErrors('corresponding_author_id')">
                <b-select v-model="paper.corresponding_author_id" expanded>
                  <option v-for="author in groupAuthors" :key="author.value" :value="author.id">{{author.full_name}}</option>
                </b-select>
              </b-field>
            </div>            
          </div>    
        </div>

        <div class="column is-quarter">
          <b-field label="Document Type" :type="fieldType('document_type')" :message="fieldErrors('document_type')">
            <b-select v-model="paper.document_type" expanded>
              <option v-for="documentType in documentTypes" :key=documentType.value>{{documentType.label}}</option>
            </b-select>
          </b-field>      
        </div>

        <div class="column is-quarter">
          <b-field label="Status" :type="fieldType('current_state')" :message="fieldErrors('current_state')">
            <b-select v-model="paper.current_state" expanded>
              <option value="writing">writing</option>
              <option value="published">published</option>
            </b-select>
          </b-field>

          <b-field v-if="paper.current_state === 'published'" label="Published At" :type="fieldType('published_at')" :message="fieldErrors('published_at')">
              <b-datepicker v-model="paper.published_at"
                placeholder="Click to select..."
                :date-formatter="dateFormatter"
                icon="calendar-alt">
              </b-datepicker>
          </b-field>       

          <b-field v-if="paper.current_state === 'published'" label="URL" :type="fieldType('published_url')" :message="fieldErrors('published_url')">
            <b-input v-model="paper.published_url"></b-input>
          </b-field>
        </div>        
      </div>
      
      <hr>
      <b-button class='is-success' native-type="submit">Create Paper</b-button>
    </form>
  </div>
</template>

<script>
import formFieldsMixin from '@/mixins/formFieldsMixin.js'

export default {
  name: "FormPaper",
  mixins: [formFieldsMixin],  
  mounted () {
    Promise.all([
        this.getGroups(), 
        this.getAuthors()
      ]).then(() => {
      this.isLoading = false
    })
  },
  data: function() {
    return {
      paper: {
        title: null,
        document_type: 'statement',
        current_state: 'writing',
        authoring_group_id: null,
        joint_group_id: null,
        corresponding_author_id: null
      },
      isLoading: true,
      groups: [],
      authors: [],
      documentTypes: [
        { value: 'statement', label: 'statement' },
        { value: 'practice_point', label: 'practice point' },        
      ],
      errors: {}
    }
  },
  computed: {
    groupAuthors () {
      return this.authors.filter((author) => author.active_group_ids.includes(this.paper.authoring_group_id))
    },
    filteredGroups () {
      return this.groups.filter((group) => group.group_type !== 'board' && group.active)      
    }
  },
  watch: {
    'paper.authoring_group_id': function () {
      this.updateCorrespondingAuthor();
    },
    authors: function () {
      this.updateCorrespondingAuthor();
    }
  },
  methods: {
    getGroups () {
      var url = `/groups`

      this.$secured.get(url)
        .then(response => {
          this.groups = response.data
          this.paper.authoring_group_id = this.filteredGroups[0].id
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get groups: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },
    getAuthors () {
      var url = `/admin/users?author=true`

      this.$secured.get(url)
        .then(response => {
          this.authors = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get authors: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },
    dateFormatter(date) {
      var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0]

      return dateString
    },    
    processForm () {
      var params = {
        paper: this.paper
      }

      this.addPaper(params)
    },
    addPaper (params) {
      this.errors = []
      
      this.$secured.post('/admin/papers', params)
      .then((response) => {
        response

        var paper = response.data

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully added paper`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = `/papers/${paper.id}`
      })
      .catch((error) => {
        console.log(error)
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to add paper: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    },
    updateCorrespondingAuthor() {
      if (this.groupAuthors.length > 0) {
        this.paper.corresponding_author_id = this.groupAuthors[0].id
      } else {
        this.paper.corresponding_author_id = null
      }
    }    
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
