<template>
  <div class="boolean-question box">
    <h5 class="is-h5 subtitle">{{ question }}</h5>

    <div class="buttons has-addons is-centered">
      <span :class="positiveClass" v-on:click="setAnswerPositive()">{{ positiveAnswer }}</span>
      <span :class="negativeClass" v-on:click="setAnswerNegative()">{{ negativeAnswer }}</span>
    </div>

    <b-field label="Comments" v-show="isNegative">
      <b-input
        type="textarea"
        :placeholder="commentsPlaceholder"
        v-model="localResponse.comments"
        @input="updateAnswered()"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "BooleanQuestion",
  props: {
    response: Object,
    question: { type: String, required: true },
    positiveAnswer: { type: String, required: true }
  },
  model: {
    prop: "response",
    event: "input"
  },
  data: function() {
    return {
      localResponse: this.response
    };
  },
  mounted() {
    this.updateAnswered();
  },
  computed: {
    negativeAnswer: function() {
      var negativeAnswer = "no";

      if (this.positiveAnswer === "no") {
        negativeAnswer = "yes";
      }

      return negativeAnswer;
    },
    baseClass: function() {
      return ["button", "is-medium"];
    },
    isPositive: function() {
      return this.localResponse.answer === this.positiveAnswer;
    },
    isNegative: function() {
      return this.localResponse.answer === this.negativeAnswer;
    },
    positiveClass: function() {
      var positiveClass = this.baseClass;

      if (this.isPositive === true) {
        positiveClass = positiveClass.concat(["is-selected", "is-success"]);
      }

      return positiveClass.join(" ");
    },
    negativeClass: function() {
      var negativeClass = this.baseClass;

      if (this.isNegative === true) {
        negativeClass = negativeClass.concat(["is-selected", "is-warning"]);
      }

      return negativeClass.join(" ");
    },
    commentsPlaceholder: function() {
      return ["If ", this.negativeAnswer, ", please tell us why..."].join("");
    },
    isAnswered: function() {
      var answered = false;

      if (this.isPositive === true) {
        answered = true;
      } else if (
        this.isNegative === true &&
        this.localResponse.comments != null &&
        this.localResponse.comments.length > 0
      ) {
        answered = true;
      }

      return answered;
    }
  },
  methods: {
    updateAnswered: function() {
      this.localResponse.answered = this.isAnswered;
    },
    setAnswerPositive: function() {
      this.localResponse.answer = this.positiveAnswer;
      this.updateAnswered();
    },
    setAnswerNegative: function() {
      this.localResponse.answer = this.negativeAnswer;
      this.updateAnswered();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
