<template>
  <div class="rate-question box">
    <h5 class="is-h5 subtitle">{{ question }}</h5>

    <p v-for="(label, index) in labels" :key="index">
      <small v-if="label.length > 0">{{index+1}}. {{label}}</small>
    </p>

    <div class="buttons has-addons is-centered">
      <span
        v-for="index in ratings"
        :class="baseClass(index)"
        :key="index"
        v-on:click="handleClick(index)"
      >{{ index }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateQuestion",
  props: {
    response: Object,
    question: { type: String, required: true },
    labels: Array,
    minimum: { type: Number, required: true },
    maximum: { type: Number, required: true }
  },
  model: {
    prop: "response",
    event: "input"
  },
  data: function() {
    return { localResponse: this.response };
  },
  mounted() {
    this.updateAnswered();
  },  
  computed: {
    ratings: function() {
      return [
        ...Array(
          this.maximum - this.minimum + 1
        ).keys()
      ].map(i => (i + this.minimum).toString());
    },
    isAnswered: function() {
      var answered = false;

      if (parseInt(this.localResponse.answer, 0) > 0) {
        answered = true;
      }

      return answered;
    }
  },
  methods: {
    updateAnswered: function() {
      this.localResponse.answered = this.isAnswered;
    },
    baseClass: function(index) {
      var baseClass = ["button", "is-medium"];

      if (this.localResponse.answer === index) {
        baseClass = baseClass.concat(["is-selected", "is-success"]);
      }

      return baseClass.join(" ");
    },
    handleClick(value) {
      this.localResponse.answer = value;
      this.updateAnswered();
      this.$emit("input", this.localResponse);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
