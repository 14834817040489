<template>
  <b-field class="file is-primary is-fullwidth" :class="{ 'has-name': !!file }">
    <b-upload
      v-model="file"
      class="file-label"
      :accept="fileTypes"
      @input="readFile"
    >
      <span class="file-cta">
        <b-icon class="file-icon" icon="upload"></b-icon>
        <span class="file-label">{{ label }}</span>
      </span>
      <span class="file-name" v-if="value && value.filename">
        {{ value.filename }}
      </span>
    </b-upload>
  </b-field>
</template>

<script>
export default {
  name: "FileAttachment",
  props: {
    label: String,
    fileTypes: String,
    value: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    filename() {
      if (this.file !== null) {
        return this.file.name;
      }

      if (
        this.value !== null &&
        this.value.url !== null &&
        this.value.url !== undefined
      ) {
        var url = this.value.url;
        return url.slice(url.lastIndexOf("/") + 1, url.lastIndexOf("?"));
      }

      return "none";
    },
  },
  methods: {
    readFile() {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        this.$emit("input", {
          data: base64String,
          filename: this.filename,
        });
      };
      reader.readAsDataURL(this.file);
    },
  },
};
</script>