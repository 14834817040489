import Vue from 'vue/dist/vue.js'
import Buefy from 'buefy'
import VueResource from 'vue-resource'

import router from './router'
import { BackendPlugin} from './backend'

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

Vue.use(BackendPlugin, { router })
Vue.use(VueResource);

import AddNoteModal from './components/common/AddNoteModal.vue'
import EditNoteModal from './components/common/EditNoteModal.vue'
import FormPaper from './components/papers/FormPaper'
import FormGroup from './components/groups/FormGroup'
import FormIntent from './components/intents/FormIntent.vue'
import FormReviewQuestionTemplate from './components/review_question_templates/FormReviewQuestionTemplate'
import LinkFollower from './components/common/LinkFollower.vue'
import ListReports from './components/reports/ListReports.vue'
import PrintButton from './components/common/PrintButton.vue'
import RankTable from './components/reviews/RankTable.vue'
import ReviewDatePicker from './components/reviews/ReviewDatePicker.vue'
import ReviewForm from './components/reviews/form/ReviewForm.vue'
import ReviewShowOverviewTab from './components/reviews/OverviewTab.vue'
import ReviewScoreRankTab from './components/reviews/ScoreRankTab.vue'
import ShowGroup from './components/groups/ShowGroup.vue'
import PromptCloseModal from './components/reviews/PromptCloseModal.vue'
import QuestionForm from './components/user_reviews/QuestionForm.vue'
import RequestChangesModal from './components/common/RequestChangesModal.vue'

document.addEventListener('DOMContentLoaded', function () {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  Vue.http.headers.common['X-CSRF-Token'] = csrfToken

  var element = document.getElementById("app")
  if(element) {
    var activeTab = parseInt(element.dataset.activeTab) || 0

    var vm = new Vue({
      el: "#app",
      data: {
        activeTab: activeTab
      },
      components: {
        AddNoteModal,
        EditNoteModal,
        PromptCloseModal,
        RequestChangesModal,
        'form-group': FormGroup,
        'form-intent': FormIntent,
        'form-paper': FormPaper,
        'form-review-question-template': FormReviewQuestionTemplate,
        'link-follower': LinkFollower,
        'list-reports': ListReports,
        'print-button': PrintButton,
        'rank-table': RankTable,
        'review-date-picker': ReviewDatePicker,
        'review-form': ReviewForm,
        'review-show-score-rank-tab': ReviewScoreRankTab,
        'review-show-overview-tab': ReviewShowOverviewTab,
        'question-form': QuestionForm,
        'show-group': ShowGroup
      },
      methods: {
        addNote: function(commentableId, commentableType) {
          this.$buefy.modal.open({ parent: this,
            component: AddNoteModal,
            hasModalCard: true,
            trapFocus: true,
            props: {
              csrfToken: csrfToken,
              commentableId: commentableId,
              commentableType: commentableType
            }
          })          
        },
        editNote: function(userCommentId, contents) {
          this.$buefy.modal.open({ parent: this,
            component: EditNoteModal,
            hasModalCard: true,
            trapFocus: true,
            props: {
              csrfToken: csrfToken,
              userCommentId: userCommentId,
              contents: contents
            }
          })        
        },
        promptDeleteNote: function(userCommentId, redirectPath) {
          this.$buefy.dialog.confirm({
            title: 'Delete Note',
            message: 'Are you sure you want to <b>delete</b> this note?',
            confirmText: 'Delete Note',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.$secured.delete(`/user_comments/${userCommentId}`)
                .then(response => {
                  window.location.href = redirectPath
                })
                .catch(error => {
                  console.error(error)
                })
            }
          })
        },           
        promptClose: function(reviewId, deadlineAt) {
          this.$buefy.modal.open({ parent: this,
            component: PromptCloseModal,
            hasModalCard: true,
            trapFocus: true,
            props: {
              csrfToken: csrfToken,
              reviewId: reviewId,
              deadlineAt: deadlineAt
            }
          })        
        },           
        requestChanges: function(reviewableId, reviewableType, action) {
          this.$buefy.modal.open({ parent: this,
            component: RequestChangesModal,
            hasModalCard: true,
            trapFocus: true,
            props: {
              csrfToken: csrfToken,
              reviewableId: reviewableId,
              reviewableType: reviewableType,
              action: action
            }
          })        
        },                
      }
    })
  } 
})
