<template>
  <form :action="formPath" method="post">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Note</p>
        <button class="delete" type="button" aria-label="close" v-on:click="cancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <div class="control">
            <input name="authenticity_token" type="hidden" :value="csrfToken">
            <input name="user_comment[id]" type="hidden" :value="userCommentId">

            <b-field label="Note">
              <b-input
                ref="contents"
                name="user_comment[contents]"
                type="textarea"
                :value="contents"
                placeholder="Enter your note..."
                rows="4"
                required
              ></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button type="submit" class="button is-primary">Save Note</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "EditNoteModal",
  props: {
    csrfToken: String,
    userCommentId: Number,
    contents: String
  },
  mounted () {
    this.$refs.contents.focus()
  },
  data: function() {
    return {};
  },
  computed: {
    formPath: function() {
      return ['/user_comments/', this.userCommentId].join('')
    }
  },
  methods: {
    cancel: function() {
      this.$emit('close');
    }
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
