<template>
  <section>
    <form @submit.prevent="processForm()">
      <b-loading v-model="isLoading"></b-loading>    

      <div class="columns">
        <div class="column is-two-thirds">
          <b-table :data="reviewables" draggable @dragstart="dragstart" @drop="drop" @dragover="dragover" @dragleave="dragleave">
            <template slot-scope="props">
              <b-table-column field="rank" label="Rank" numeric>
                {{ props.row.global_rank }}
              </b-table-column>               

              <b-table-column field="id" class="invisible" numeric>
                {{ props.row.id }}
              </b-table-column>     

              <b-table-column field="title" label="Document">
                {{ props.row.reviewable.title }}
              </b-table-column>

              <b-table-column field="document_type" label="Type" centered>
                {{ props.row.reviewable.document_type }}
              </b-table-column> 

              <b-table-column field="revision" label="Revision" centered>
                {{ props.row.reviewable.document_subtype }}
              </b-table-column>                                       
            </template>
          </b-table>
        </div>

        <div class="column is-third">
          <h5 class="subtitle is-h5">Instructions</h5>
          <div class="box">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>Drag and drop the reviewed documents to reorder them to suit your preference from highest to lowest.</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <b-button class='is-primary' native-type="submit">Save Changes</b-button>                  
    </form>
  </section>
</template>

<script>
export default {
  name: "RankTable",
  props: {
    id: Number
  },
  data() {
    return {
      reviewables: [],
      isLoading: true,
      draggingRow: null,
      draggingRowIndex: null
    }
  },
  mounted() {
    this.getUserReviews()
  },
  methods: {
    processForm () {
      var params = {
        intent_review_board: {
          user_reviews_attributes: this.reviewables.map(reviewable => { return { id: reviewable.id, global_rank: reviewable.global_rank } } )
        }
      }

      var url = `/reviews/${this.id}`

      this.$secured.put(url, params)
      .then((response) => {
        response

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully updated ranks`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = `/reviews/${this.id}/review`
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to update ranks: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    },    
    getUserReviews() {
      var url = `/reviews/${this.id}/user_reviews`

      this.$secured.get(url)
        .then(response => {
          this.reviewables = response.data

          this.isLoading = false
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get reviewables: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
        })    
      })
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'move'
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'move'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      const draggingRowIndex = this.draggingRowIndex

      const item = this.reviewables[draggingRowIndex]

      if (droppedOnRowIndex > draggingRowIndex) {
        for (let i = draggingRowIndex; i < droppedOnRowIndex; i++) {
          this.reviewables[i] = this.reviewables[i + 1]
        }
      } else {
        for (let i = draggingRowIndex; i > droppedOnRowIndex; i--) {
          this.reviewables[i] = this.reviewables[i - 1]
        }
      }

      this.reviewables[droppedOnRowIndex] = item

      for (let i = 0; i < this.reviewables.length; i++) {
        this.reviewables[i].global_rank = i + 1
      }
    }
  }
}
</script>