<template>
  <div class="comments-question box">
    <b-field label="Comments">
      <b-input
        type="textarea"
        :placeholder="question"
        v-model="localResponse.comments"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "CommentsQuestion",
  props: {
    response: Object,
    question: { type: String, required: true }
  },
  model: {
    prop: "response",
    event: "input"
  },
  data: function() {
    return {
      localResponse: this.response
    };
  },
  mounted() {
    this.updateAnswered();
  },
  computed: {
    baseClass: function() {
      return ["button", "is-medium"];
    },
  },
  methods: {
    updateAnswered: function() {
      this.localResponse.answered = true;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
