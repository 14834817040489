<template>
  <b-field>
    <b-select expanded v-model="selectedState" @input="follow">
      <option value="">all</option>
      <option v-for="(_, index) in parsedOptions" :key="index" :value="index">{{parsedOptions[index]}}</option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "LinkFollower",
  props: {
    state: String,
    options: String
  },
  data: function() {
    return {
      selectedState: this.state
    }
  },
  computed: {
    parsedOptions () {
      return JSON.parse(this.options)
    }
  },
  methods: {
    follow: function() {
      var url = window.location.href.split('?')[0]

      url = url + "?state=" + this.selectedState
      window.location.href = url
    }
  }
}
</script>
