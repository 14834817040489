<template>
  <b-button class='is-link' icon-left='print' @click="openAllUrls" :label="label"></b-button>
</template>

<script>
export default {
  name: "PrintButton",
  props: {
    label: String,
    urls: Array
  },
  methods: {
   openAllUrls() {
      this.urls.forEach((url, index) => {
        setTimeout(() => {
          const newWindow = window.open(url, '_blank')
          if (!newWindow) {
            console.error("Failed to open new tab for URL:", url)
          }
        }, index * 500) // Opens each URL with a delay
      })
    }
  }
}
</script>