import strftime from '@/strftime.js'

export default {
  methods: {
    formatCurrency (dollars, currency='USD') {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency
      })

      return formatter.format(dollars)
    },
    formatDate (date) {
      var formatted = '-'

      if (date != null) {
        formatted = strftime('%Y-%m-%d', new Date(date))
      }

      return formatted
    },
    formatDateTime (date) {
      var formatted = '-'

      if (date != null) {
        formatted = strftime('%Y-%m-%d %H:%M', new Date(date))
      }

      return formatted
    }         
  }
}
