<template>
  <div>
    <form id="reviewQuestionTemplateForm" @submit.prevent="processForm()">
      <div class="columns">
        <div class="column">
          <b-field label="Label" :type="fieldType('label')" :message="fieldErrors('label')">
            <b-input v-model="reviewQuestionTemplate.label" :autofocus="true"></b-input>
          </b-field>          
        </div>
      </div>

      <div class="columns">
        <div class="column is-third">
          <b-field label="Review Type" :type="fieldType('review_type')" :message="fieldErrors('review_type')">
            <b-select v-model="reviewQuestionTemplate.review_type" :disabled="isEdit()" is-fullwidth>
              <option v-for="reviewType in reviewTypes">{{reviewType}}</option>
            </b-select>
          </b-field>      
          <p class="help">Review type may only be set on creation of the question.</p>
        </div>    

        <div class="column is-third ">
          <div class='columns'>
            <div class='column is-half'>
              <b-field label="Question Type" :type="fieldType('question_type')" :message="fieldErrors('question_type')">
                <b-select v-model="reviewQuestionTemplate.question_type" is-fullwidth>
                  <option v-for="questionType in questionTypes">{{questionType}}</option>
                </b-select>
              </b-field>
            </div>

            <div class='column is-half'>
              <b-field label="Voting Indicators" :type="fieldType('support')" :message="fieldErrors('support')" v-if="reviewQuestionTemplate.question_type==='yes-no'">
                <b-checkbox v-model="reviewQuestionTemplate.support">
                  Record as support?
                </b-checkbox>
              </b-field>
            </div>
          </div>

          <div class='columns' v-if="reviewQuestionTemplate.question_type==='rate'">
            <div class="column">
              <b-field label="Minimum" :type="fieldType('minimum')" :message="fieldErrors('minimum')">
                <b-numberinput v-model="reviewQuestionTemplate.minimum"></b-numberinput>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Maximum" :type="fieldType('maximum')" :message="fieldErrors('maximum')">
                <b-numberinput v-model="reviewQuestionTemplate.maximum"></b-numberinput>
              </b-field>
            </div>
          </div>
        </div>    

        <div class="column is-third">
          <b-field label="Status" :type="fieldType('active')" :message="fieldErrors('active')">
            <b-checkbox v-model="reviewQuestionTemplate.active">
              Active
            </b-checkbox>
          </b-field>
        </div>
      </div>

      <hr>
      <b-button class='is-success' native-type="submit">Save Changes</b-button>
    </form>
  </div>
</template>

<script>
import formFieldsMixin from '@/mixins/formFieldsMixin.js'

export default {
  name: "FormReviewQuestionTemplate",
  props: {
    id: String
  },
  mixins: [formFieldsMixin],  
  mounted () {
    if(this.isEdit()) {
      this.getReviewQuestionTemplate()
    }    
  },
  data: function() {
    return {
      reviewQuestionTemplate: {
        review_type: 'IntentReview::Board',
        question_type: 'rate'
      },
      reviewTypes: [
        'IntentReview::Board', 
        'PaperReview::Expert', 
        'PaperReview::ExpertFollowup', 
        'PaperReview::Board', 
        'PaperReview::BoardFollowup', 
        'PaperReview::Annual'
      ],
      questionTypes: [
        'rate', 
        'comments', 
        'yes-no', 
        'no-yes'
      ],
      errors: {}
    }
  },
  methods: {
    getReviewQuestionTemplate () {
      var url = `/admin/review_question_templates/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.reviewQuestionTemplate = response.data
        })
        .catch((error) => {
        //  this.$session.setError(error.msg)
        }) 
    },
    processForm () {
      var params = {
        review_question_template: this.reviewQuestionTemplate
      }

      if(this.isEdit()) {
        this.updateTemplate(params)
      } else {
        this.addTemplate(params)
      }
    },
    addTemplate (params) {
      this.$secured.post('/admin/review_question_templates', params)
      .then((response) => {
        response

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully added template`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = '/admin/review_question_templates'
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to add template: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    },
    updateTemplate (params) {
      this.$secured.put(`/admin/review_question_templates/${this.id}`, params)
      .then((response) => {
        response

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully updated template`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = '/admin/review_question_templates'
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to update template: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    }
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
