<template>
  <form :action="formPath" method="post">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Request Changes</p>
        <button class="delete" type="button" aria-label="close" v-on:click="cancel"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <div class="control">
            <input name="authenticity_token" type="hidden" :value="csrfToken">

            <b-field label="Changes Requested">
              <b-input
                ref="reason"
                :name="reasonFieldName"
                type="textarea"
                placeholder="Enter the reason for requested changes..."
                rows="4"
                required
              ></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button type="submit" class="button is-warning">Request Changes</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "RequestChangesModal",
  props: {
    csrfToken: String,
    reviewableId: Number,
    reviewableType: String,
    action: String
  },
  mounted () {
    this.$refs.reason.focus()
  },
  data: function() {
    return {};
  },
  computed: {
    formPath: function() {
      return ['/', this.reviewableType, 's/', this.reviewableId, '/', this.action].join('')
    },
    reasonFieldName: function() {
      return [this.reviewableType, '[reason]'].join('')
    }
  },
  methods: {
    cancel: function() {
      this.$emit('close');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>



