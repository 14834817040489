<template>
  <b-datepicker v-model="reviewAt"
    placeholder="Click to select..."
    :date-formatter="dateFormatter"
    v-on:input="syncHiddenField"
    icon="calendar-alt">
  </b-datepicker>
</template>


<script>
export default {
  name: "ReviewDatePicker",
  props: {
    hiddenField: String
  },
  data() {
    var elements = document.getElementsByClassName(this.hiddenField)
    var element = elements[0]
    var reviewAt = null

    if(element != null) {
      // pluck the date from the input form
      var reviewAtString = element.value

      if(reviewAtString != "") {
        reviewAt = new Date(reviewAtString)

        // Javascript dates are just the worst... just awful garbage
        reviewAt.setDate(reviewAt.getDate() + 1)
      }
    }

    return {
      reviewAt: reviewAt
    }
  },
  methods: {
    syncHiddenField(date) {
      var elements = document.getElementsByClassName(this.hiddenField)
      var element = elements[0]

      if(element != null) {
        element.value = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0]
      }          
    },    
    dateFormatter(date) {
      var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0]

      return dateString
    },
  }
}
</script>