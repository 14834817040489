<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>

    <form v-if="!isLoading" id="intentForm" @submit.prevent="processForm()">
      <h5 class='subtitle is-h5'>Document Details</h5>

      <!-- row 1 -->
      <div class="columns">
        <div class="column is-one-quarter">
           <b-field label="Corresponding Author" :type="fieldType('corresponding_author_id')" :message="fieldErrors('corresponding_author_id')">
            <b-select v-model="intent.corresponding_author_id" expanded>
              <option v-for="author in filteredCorrespondingAuthors" :key="author.id" :value="author.id">{{author.full_name}}</option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-one-quarter">
           <b-field label="Authoring Group" :type="fieldType('authoring_group_id')" :message="fieldErrors('authoring_group_id')">
            <b-select v-model="intent.authoring_group_id" expanded>
              <option v-for="group in filteredAuthoringGroups" :key="group.id" :value="group.id">{{group.name}}</option>
            </b-select>
          </b-field>

           <b-field label="Joint CPS Group" :type="fieldType('joint_group_id')" :message="fieldErrors('joint_group_id')">
            <b-select v-model="intent.joint_group_id" expanded>
              <option value=null>None</option>
              <option v-for="group in authoringGroups" :key="group.id" :value="group.id" :disabled="group.id === intent.authoring_group_id">{{group.name}}</option>
            </b-select>
          </b-field>          
        </div>

        <div class="column is-half">
          <b-field label="Working Title" :type="fieldType('title')" :message="fieldErrors('title')">
            <b-input v-model="intent.title" :autofocus="true"></b-input>
          </b-field>    
        </div>  
      </div>

      <!-- row 2 -->
      <div class="columns">
        <div class="column is-one-quarter">
           <b-field label="Document Type" :type="fieldType('document_type')" :message="fieldErrors('document_type')">
            <b-select v-model="intent.document_type" expanded>
              <option value="statement" :disabled="authoringGroup && authoringGroup.group_type === 'section'">Statement</option>
              <option value="practice point">Practice Point</option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-half">
          <b-field label="If a revision, please choose the current document title" :type="fieldType('revision_id')" :message="fieldErrors('revision_id')">
            <b-select v-model="intent.revision_id" expanded>
              <option value=null>Not applicable</option>
              <option v-for="paper in filteredPapers" :key="paper.id" :value="paper.id">{{paper.title}}</option>
            </b-select>
          </b-field>

          <b-field v-if="isAdmin" label="If a resubmission, please choose the rejected document title" :type="fieldType('resubmission_id')" :message="fieldErrors('resubmission_id')">
            <b-select v-model="intent.resubmission_id" expanded>
              <option value=null>Not applicable</option>
              <option v-for="intent in resubmissionIntents" :key="intent.id" :value="intent.id">{{intent.title}}</option>
            </b-select>
          </b-field>          
        </div>

        <div class="column is-one-quarter">
          <b-field label="If revision, should current document remain online?" :type="fieldType('leave_current_online')" :message="fieldErrors('leave_current_online')">
            <div class="block">
              <b-radio v-model="intent.leave_current_online" :native-value="true">Yes</b-radio>
              <b-radio v-model="intent.leave_current_online" :native-value="false">No</b-radio>         
            </div>         
          </b-field>
        </div>
      </div>

      <!-- row 3 -->
      <div class="columns">
        <div class="column is-one-quarter">
        </div>
        
        <div class="column is-three-quarters">
          <div class="box">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    If you are considering developing a joint document with an external organzation, please review the following CPS <a aria-label="link" :href="jointFormUrl" target="blank">Policy on Joint Statements with other Organizations</a>.
                  </p>
                </div>
              </div>
            </article>
          </div>    
        </div>
      </div>

      <h5 class='subtitle is-h5'>Co-authors</h5>

      <div class="columns">
        <div class="column is-quarter">
          <b-field label="Group Member Co-authors">
            <b-select multiple expanded native-size="5" v-model="intent.coauthor_ids">
              <option v-for="coauthor in filteredCoauthors" :key="coauthor.id" :value="coauthor.id">{{coauthor.full_name}}</option>
            </b-select>
          </b-field>
          <p class="help is-default">Use browser's defaults (Ctrl or Cmd) to select multiple</p>
        </div>

        <div class="column is-three-quarters">
          <div class="field">
            <label class="label">Non-Group Co-authors</label>
            <div class="box">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
                      Non-group member co-authors must have the approval of the CPS Board. For details see the <a aria-label="link" href="https://members.cps.ca/committees-and-sections/authorship-guidelines/" target="blank">authorship guidelines</a>.
                    </p>
                    <p>
                      If you wish to engage a non-group member co-author, please provide the following. Make sure to include a current CV and signed <a aria-label="link" :href="disclosureFormUrl" target="blank">disclosure form</a>.
                    </p>
                  </div>
                </div>
              </article>
              <br/>
              <b-button @click="addExternalCoauthor">Add External Coauthor</b-button>
              <hr>
              <div id='external-coauthors'>
                <span v-for="(coauthor, index) in intent.external_coauthors">
                  <span  v-if="intent.external_coauthors[index]._destroy !== 1">
                    <div class='columns'>
                      <div class='column is-1'>
                        <a class="delete" @click="deleteExternalCoauthor(index)"></a>
                      </div>

                      <div class='column is-3'>
                        <b-field label="Full Name">
                          <b-input v-model="intent.external_coauthors[index].full_name" required placeholder="John Smith">
                          </b-input>
                        </b-field>
                      </div>

                      <div class='column is-3'>
                        <b-field label="Title">
                          <b-input v-model="intent.external_coauthors[index].title" required placeholder="Professor">
                          </b-input>
                        </b-field>
                      </div>

                      <div class='column is-3'>
                        <b-field label="Institution/Affiliation">
                          <b-input v-model="intent.external_coauthors[index].institution" required placeholder="Ottawa University">
                          </b-input>
                        </b-field>
                      </div>

                      <div class='column is-2'>
                        <b-field label="Role">
                          <b-input v-model="intent.external_coauthors[index].role" required placeholder="Content Expert">
                          </b-input>
                        </b-field>
                      </div>
                    </div>

                    <div class='columns'>
                      <div class='column is-1'>
                      </div>
                      
                      <div class='column is-11'>
                        <file-attachment v-model="intent.external_coauthors[index].resume" label="Attach a resume..." fileTypes=".pdf,.doc,.docx">
                        </file-attachment>
                      </div>
                    </div>

                    <div class='columns'>
                      <div class='column is-1'>
                      </div>
                      
                      <div class='column is-11'>
                        <file-attachment v-model="intent.external_coauthors[index].disclosure_form" label="Attach a disclosure form..." fileTypes=".pdf,.doc,.docx">
                        </file-attachment>
                      </div>
                    </div>

                    <hr/>
                  </span>
                </span>
              </div>
            </div> 
          </div>
        </div>  
      </div>

      <h5 class='subtitle is-h5'>Purpose and Audience</h5>

      <b-field :label="labels['purpose']" :type="fieldType('purpose_description')" :message="fieldErrors('purpose_description')">
        <b-input v-model="intent.purpose_description" type="textarea" :placeholder="labels['purpose']"></b-input>
      </b-field>      

      <b-field :label="labels['gap']" :type="fieldType('gap_description')" :message="fieldErrors('gap_description')">
        <b-input v-model="intent.gap_description" type="textarea" :placeholder="labels['gap']"></b-input>
      </b-field>   

      <b-field :label="labels['guidelines']" :type="fieldType('guidelines_description')" :message="fieldErrors('guidelines_description')">
        <b-input v-model="intent.guidelines_description" type="textarea" :placeholder="labels['guidelines']"></b-input>
      </b-field>   
      
      <b-field :label="labels['anticipated_recommendations']" :type="fieldType('anticipated_recommendations')" :message="fieldErrors('anticipated_recommendations')">
        <b-input v-model="intent.anticipated_recommendations" type="textarea" :placeholder="labels['anticipated_recommendations']"></b-input>
      </b-field>   

      <b-field :label="labels['expected_changes']" :type="fieldType('expected_changes')" :message="fieldErrors('expected_changes')">
        <b-input v-model="intent.expected_changes" type="textarea" :placeholder="labels['expected_changes']"></b-input>
      </b-field>  

      <b-field label="Target Audience(s)">
        <div id="target-audience" v-if="isStatement">
          <p class="help is-default">Check all that apply</p>
          <div class="box">
            <div class="columns">
              <div v-for="audienceGroup in sliceIntoChunks(audiences, 2)" class="column is-one-quarter">
                <div v-for="audience in audienceGroup">
                  <b-checkbox v-model="intent.audience_ids" :native-value="audience.id">{{audience.name}}</b-checkbox>
                </div>
              </div>

              <div class="column is-quarter">
                <b-field label="Other" :type="fieldType('other_audience')" :message="fieldErrors('other_audience')">
                  <b-input v-model="intent.other_audience"></b-input>
                </b-field>                         
                <p class="help is-default">Please specify</p>
              </div>      
            </div>
          </div>
        </div>

        <div class="box" v-if="isPracticePoint">
          <p>{{labels['practice_point_audience']}}</p>
        </div>          
      </b-field>  

      <h5 class='subtitle is-h5'>Outline and Development</h5>
      <file-attachment v-model="intent.outline" label="Attach an outline..." fileTypes=".pdf,.doc,.docx">
      </file-attachment>

      <b-field :label="labels['search_strategy']" :type="fieldType('search_strategy')" :message="fieldErrors('search_strategy')">
        <b-input v-model="intent.search_strategy" type="textarea" :placeholder="labels['search_strategy']"></b-input>
      </b-field>

      <b-field :label="labels['multiple_choice']">
        <a aria-label="link" href="https://www.cps.ca/en/ecme" target="blank">Click here for more information.</a>
      </b-field>

      <b-field>
        <div class="block">
          <b-radio v-model="intent.multiple_choice" :native-value="true">Yes</b-radio>
          <b-radio v-model="intent.multiple_choice" :native-value="false">No</b-radio>
        </div>
      </b-field>

      <b-field :label="labels['board_consideration']" :type="fieldType('board_notes')" :message="fieldErrors('board_notes')">
        <b-input v-model="intent.board_notes" type="textarea" :placeholder="labels['board_consideration']"></b-input>
      </b-field>

      <h5 class='subtitle is-h5'>Reviewers</h5>

      <b-field :label="labels['internal_groups']">
        <div class="box">
          <div class="columns">
            <div class="column is-third">
              <h5>Committees</h5>
              <div v-for="group in committeeReviewGroups">
                <label>
                  <b-checkbox v-model="intent.peer_reviewer_ids" :native-value="group.id" :disabled="group.id === intent.authoring_group_id || group.id === intent.joint_group_id">{{group.name}}</b-checkbox>
                </label>
              </div>
            </div>

            <div class="column is-third">
              <h5>Sections</h5>
              <div v-for="group in sectionReviewGroups">
                <label>
                  <b-checkbox v-model="intent.peer_reviewer_ids" :native-value="group.id" :disabled="group.id === intent.authoring_group_id || group.id === intent.joint_group_id">{{group.name}}</b-checkbox>
                </label>
              </div>
            </div>

            <div class="column is-third">
              <h5>Other</h5>
              <div v-for="group in otherReviewGroups">
                <label>
                  <b-checkbox v-model="intent.peer_reviewer_ids" :native-value="group.id" :disabled="group.id === intent.authoring_group_id || group.id === intent.joint_group_id">{{group.name}}</b-checkbox>
                </label>
              </div>
            </div>      
          </div>
        </div>
      </b-field>     

      <b-field :label="labels['external_organizations']" :type="fieldType('external_organizations')" :message="fieldErrors('external_organizations')">
        <b-input v-model="intent.external_organizations" type="textarea" :placeholder="labels['external_organizations']"></b-input>
      </b-field>   

      <b-field :label="labels['other_commenters']" :type="fieldType('other_commenters')" :message="fieldErrors('other_commenters')">
        <b-input v-model="intent.other_commenters" type="textarea" :placeholder="labels['other_commenters']"></b-input>
      </b-field>   
      <hr>
      <b-button class='is-primary' native-type="submit" @click="asSubmit = false">Save Changes</b-button>
      <b-button v-if="canSubmit" class='is-success' native-type="submit" @click="asSubmit = true">Submit</b-button>
    </form>

    <hr>
  </div>
</template>

<script>
import formFieldsMixin from '@/mixins/formFieldsMixin.js'
import FileAttachment from '@/components/common/FileAttachment'

const renameDict = { external_coauthors: 'external_coauthors_attributes' }

export default {
  name: "FormIntent",
  props: {
    id: Number,
    currentUserId: Number,
    isAdmin: Boolean,
    canSubmit: Boolean,
    labels: Object,
    bucket: String
  },
  components: {
    'file-attachment': FileAttachment
  },
  mixins: [formFieldsMixin],  
  data: function() {
    return {
      activeTab: 0,
      isLoading: true,
      intent: {
        corresponding_author_id: this.currentUserId,
        creator_id: this.currentUserId,
        authoring_group_id: null,
        joint_group_id: null,
        document_type: 'statement',
        leave_current_online: true,
        resubmission_id: null,
        revision_id: null,
        coauthor_ids: [],
        audience_ids: [],
        priority_ids: [],
        peer_reviewer_ids: [],
        external_coauthors: [],
        outline: {
          data: null,
          filename: null
        }
      },
      audiences: [],
      correspondingAuthors: [],
      coauthors: [],
      authoringGroups: [],
      reviewGroups: [],
      priorities: [],
      papers: [],
      intents: [],
      errors: {},
      asSubmit: false
    }
  },
  mounted () {
    const loadData = () => {
      return Promise.all([
        this.getCorrespondingAuthors(), 
        this.getCoauthors(),
        this.getAudiences(),
        this.getAuthoringGroups(),
        this.getReviewGroups(),
        this.getPapers(),
        this.getIntents(),
        this.getPriorities()
      ])
    }

    if (this.isEdit()) {
      this.getIntent().then(() => {
        loadData().then(() => {
          this.isLoading = false
        })
      })
    } else {
      loadData().then(() => {
        this.isLoading = false
      })
    }
  },
  watch: {
    filteredAuthoringGroups: function () {
      // set a default authoring group
      if(!this.filteredAuthoringGroups.find(group => group.id === this.authoring_group_id)) {
        this.intent.authoring_group_id = this.correspondingAuthorGroupIds[0]
      }
    },
    authoringGroup: function () {
      // switch to a practice point if new authoring group is a section
      if(this.authoringGroup.group_type === 'section' && this.isStatement) {
        this.intent.document_type = 'practice_point'
      }

      // switch to no joint group if same
      if(this.intent.authoring_group_id === this.intent.joint_group_id) {
        this.intent.joint_group_id = null
      }
    },
    filteredPapers: function () {
      if(this.intent.revision_id !== null && !this.filteredPapers.find(paper => paper.id === this.intent.revision_id)) {
        this.intent.revision_id = null
      }
    }
  }, 
  computed: {
    disclosureFormUrl () {
      return `https://${this.bucket}.s3.amazonaws.com/forms/Full.disclosure.form.-.Policy.and.Leadership.pdf`
    },
    jointFormUrl () {
      return `https://${this.bucket}.s3.amazonaws.com/forms/Policy on Joint Statements with Other Organizations Apr 2022.pdf`
    },
    authoringGroup () {
      return this.authoringGroups.find(group => group.id === this.intent.authoring_group_id)
    },
    correspondingAuthor () {
      return this.correspondingAuthors.find(author => author.id === this.intent.corresponding_author_id)
    },
    correspondingAuthorGroupIds () {
      if(this.correspondingAuthor === null || this.correspondingAuthor === undefined) {
        return []
      }

      return this.correspondingAuthor.active_group_ids
    },
    filteredCoauthors () {
      return this.coauthors.filter(coauthor => (coauthor.id !== this.intent.corresponding_author_id && (coauthor.active_group_ids.includes(this.intent.authoring_group_id) || coauthor.active_group_ids.includes(this.intent.joint_group_id))))
    },
    filteredCorrespondingAuthors () {
      if(this.isAdmin) {
        return this.correspondingAuthors
      }

      return this.correspondingAuthors.filter(author => author.id === this.intent.corresponding_author_id)
    },
    filteredAuthoringGroups () {
      return this.authoringGroups.filter(group => this.correspondingAuthorGroupIds.includes(group.id))
    },
    filteredPapers () {
      return this.papers
    },
    resubmissionIntents () {
      return this.intents.filter((intent) => intent.corresponding_author_id === this.intent.corresponding_author_id)
    },    
    committeeReviewGroups () {
      return this.reviewGroups.filter(group => group.group_type === 'committee')
    },
    sectionReviewGroups () {
      return this.reviewGroups.filter(group => group.group_type === 'section')
    },
    otherReviewGroups () {
      return this.reviewGroups.filter(group => group.group_type !== 'committee' && group.group_type !== 'section' && group.group_type !== 'board')
    },    
    isStatement () {
      return this.intent.document_type === 'statement'
    },
    isPracticePoint () {
      return this.intent.document_type === 'practice point'
    }
  },
  methods: {
    addExternalCoauthor () {
      this.intent.external_coauthors.push(
        { 
          id: null, 
          full_name: null, 
          role: null, 
          institution: null, 
          title: null, 
          disclosure_form: null, 
          resume: null,
          _destroy: null
        }
      )
    },    
    getCorrespondingAuthors () {
      var url = `/users?author=true`

      this.$secured.get(url)
        .then(response => {
          this.correspondingAuthors = response.data

          if(this.intent.corresponding_author_id === null) {
            this.intent.corresponding_author_id = this.correspondingAuthors[0].id
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get corresponding authors: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },
    getCoauthors () {
      var url = `/users?coauthor=true`

      this.$secured.get(url)
        .then(response => {
          this.coauthors = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get coauthors: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },    
    getAudiences () {
      var url = `/audiences`

      this.$secured.get(url)
        .then(response => {
          this.audiences = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get corresponding audiences: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },    
    getAuthoringGroups () {
      var url = `/groups?author=true`

      this.$secured.get(url)
        .then(response => {
          this.authoringGroups = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get authoring groups: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })    
    },
    getReviewGroups () {
      var url = `/groups?review=true`

      this.$secured.get(url)
        .then(response => {
          this.reviewGroups = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get review groups: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })    
    },  
    getPriorities () {
      var url = `/priorities`

      this.$secured.get(url)
        .then(response => {
          this.priorities = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get review priorities: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })    
    },        
    getPapers () {
      var url = `/papers?state=published&sort_order=title`

      this.$secured.get(url)
        .then(response => {
          this.papers = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get papers: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })    
    },
    getIntents () {
      var url = `/intents?state=not_approved&sort_order=title`

      this.$secured.get(url)
        .then(response => {
          this.intents = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get intents: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })    
    },    
    getIntent () {
      var url = `/intents/${this.id}`

      return this.$secured.get(url)
        .then(response => {
          this.intent = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get intent: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })    
    },
    sliceIntoChunks (arr, chunkSize) {
      const res = []
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize)
        res.push(chunk)
      }

      return res
    },
    processForm () {
      var params = {
        intent: this.intent
      }

      if(params.intent.outline.data === null) {
        delete params.intent.outline
      }

      params.intent.external_coauthors.forEach((coauthor, index) => {
        if(coauthor._destroy === 1) {
          delete params.intent.external_coauthors[index].disclosure_form
          delete params.intent.external_coauthors[index].resume
        } else {
          if(coauthor.resume.data === null) {
            delete params.intent.external_coauthors[index].resume
          }

          if(coauthor.disclosure_form.data === null) {
            delete params.intent.external_coauthors[index].disclosure_form
          }
        }
      })

      params = this.renameKeys(renameDict, params)

      if(this.isEdit()) {
        this.updateIntent(params)
      } else {
        this.addIntent(params)
      }
    },
    deleteExternalCoauthor (index) {
      var existingRecord = this.intent.external_coauthors[index]._destroy !== null

      if(existingRecord) {
        this.intent.external_coauthors[index]._destroy = 1
      } else {
        this.intent.external_coauthors.splice(index, 1)
      }
    },
    addIntent (params) {
      this.$secured.post('/intents', params)
      .then((response) => {
        var id = response.data.id

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully added intent`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = `/intents/${id}`
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to add intent: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    },
    updateIntent (params) {
      var url = `/intents/${this.id}`

      if(this.asSubmit) {
        url = url + "/submit"
      }

      this.$secured.put(url, params)
      .then((response) => {
        response

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully updated intent`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = `/intents/${this.id}`
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to update intent: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    }  
  }
}
</script>
