<template>
  <section>
    <b-loading v-model="isLoading"></b-loading>  

    <div v-if="!isLoading" class="columns">
      <div class="column">
        <label class='label'>Name</label>
        <p>{{group.name}}</p>
      </div>
      <div class="column">
        <label class='label'>Group Type</label>
        <p>{{group.group_type}}</p>
      </div>
      <div class="column">
        <label class='label'>Active Members</label>
        <p>{{activeMembers.length}}</p>              
        <br/>
        <label class='label'>Members</label>
        <p>{{group.group_members.length}}</p>
      </div>              
      <div class="column">
        <label class='label'>Status</label>
        <p><span :class="statusClass(group.active)">{{group.active ? 'active' : 'inactive'}}</span></p>
        <br/>
        <label class='label'>Can Review?</label>
        <p>{{group.can_review ? 'yes' : 'no'}}</p>              
      </div>
    </div>

    <b-table v-if="!isLoading" :data="group.group_members" v-slot="props">
      <b-table-column field="user.full_name" label="Member" width="500" sortable>
        <a v-bind:href="props.row.user.url" target="_blank">{{props.row.user.full_name}}</a>
      </b-table-column> 

      <b-table-column field="role_type.position" label="Role" sortable>
        {{props.row.role_type.position}}
      </b-table-column> 

      <b-table-column field="organization" label="Organization" sortable>
        {{props.row.organization}}
      </b-table-column>       

      <b-table-column field="active" label="Active">
        <span :class="statusClass(props.row.active)">{{props.row.active ? 'active' : 'inactive'}}</span>
      </b-table-column>       
      
    </b-table>
  </section>
</template>

<script>

export default {
  name: "ShowGroup",
  props: {
    id: String
  },
  mounted () {
    this.getGroup()
  },
  data: function() {
    return {
      group: null,
      isLoading: true
    }
  },
  computed: {
    activeMembers () {
      return this.group.group_members.filter((groupMember) => groupMember.active)
    }
  },
  methods: {
    getGroup () {
      var url = `/admin/groups/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.group = response.data
          this.isLoading = false
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get group: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })
      },
      statusClass (active) {
        return `tag ${active ? 'is-success' : 'is-warning'}`
      }       
    }
  }
</script>