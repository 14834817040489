<template>
  <div class="question-form">
    <form :action="saveAction" method="post" v-if="userReview !== null">
      <div v-for="(answer, index) of userReview.user_review_answers">
        <BooleanQuestion
          v-if="answer.question.type=='boolean' || answer.question.type=='yes-no' || answer.question.type=='no-yes'"
          :question="answer.question.label"
          :positiveAnswer="answer.question.positive_answer"
          v-model="userReview.user_review_answers[index]"
        />
        <RateQuestion
          v-if="answer.question.type=='rate'"
          :question="answer.question.label"
          :labels="answer.question.labels"
          :minimum="answer.question.minimum"
          :maximum="answer.question.maximum"
          v-model="userReview.user_review_answers[index]"
        />
        <CommentsQuestion
          v-if="answer.question.type=='comments'"
          :question="answer.question.label"
          :labels="answer.question.labels"
          v-model="userReview.user_review_answers[index]"
        />        
        <hr>
      </div>

      <hr>
      <div class="buttons">
        <b-button
          type="is-primary"
          :disabled="!allAnswered"
          title="All questions must be answered to submit this review."
          v-on:click="submitReview"
          v-if="showSubmit"
        >Submit</b-button>

        <b-button v-if="!showSubmit" type="is-success" v-on:click="saveReview" :disabled="!allAnswered">Save Changes</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import BooleanQuestion from "./BooleanQuestion";
import RateQuestion from "./RateQuestion";
import CommentsQuestion from "./CommentsQuestion";

export default {
  name: "QuestionForm",
  components: {
    BooleanQuestion,
    RateQuestion,
    CommentsQuestion
  },
  props: {
    id: String,
    showSubmit: Boolean
  },
  data: function() {
    return {
      userReview: null
    };
  },
  mounted() {
    this.getUserReview()
  },
  computed: {
    allAnswered () {
      if(this.userReview === null) {
        return false
      }

      return this.userReview.user_review_answers.every(answer => answer.answered === true);
    },
    saveAction () {
      return `/user_reviews/${this.id}`
    },
    submitAction () {
      return `/user_reviews/${this.id}/submit`
    },   
    params () {
      var params = {
        user_review: {
          user_review_answers_attributes: this.userReview.user_review_answers
        }
      };

      return params;
    }
  },
  methods: {
    getUserReview () {
      var url = `/user_reviews/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.userReview = response.data

          this.isLoading = false
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get user review: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
        })    
      })
    },
    saveReview () {
      this.$http.patch(`${this.saveAction}`, this.params).then(response => {
        location = response.body.location
      }
    )},
    submitReview () {
      this.$http.patch(`${this.submitAction}`, this.params).then(response => {
        location = response.body.location
      }
    )},     
  }
}
</script>
